<template>
  <div class="ques-style">
    <div class="t1">{{language==1?info.title:info.Etitle}}</div>
    <div v-html="language==1?info.content:info.Econtent"></div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  components: {},
  data() {
    return {
      info:{}
    };
  },
  computed:{
    ...mapState(["language"]),
  },
  created(){
    this.getDetail()
  },
  mounted(){},
  methods: {
    getDetail(){
      this.$http.get('/index/problemDetail',{id:this.$route.query.id}).then(res=>{
        this.info = res.data
      })
    },
  }
};
</script>

<style lang="less">
.ques-style{
  padding: 30px 40px;
  .t1{
    font-size: 12px;
font-family: PingFang SC;
font-weight: bold;
color: #323232;
margin-bottom: 13px;
  }
}
</style>